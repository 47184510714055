import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { LoginPage } from '../../pages/login';
import { TermsPage } from '../../pages/terms';
import { LauncherPage } from '../../pages/launcher';
import { VerificationPage } from '../../pages/verification';
import { EmailVerificationPage } from '../../pages/emailVerification';
import { SMSVerificationPage } from '../../pages/SMSVerificationPage';
import { SettingsPage } from '../../pages/settings';
import { Totp } from '../../pages/totp';
import { Email } from '../../pages/email';
import { VerifyEmailId } from '../../pages/verifyEmailId';
import { SMS } from '../../pages/sms';
import { VerifyPhone } from '../../pages/verifyPhone';
import LogoutPage from '../../pages/logout';
import OauthPage from '../../pages/oauth';
// import { Provider } from 'react-redux';
import '../../scss/main.scss';
import theme from '../../utils/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <React.Fragment>
          <Route exact path="/" component={LoginPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/launcher" component={LauncherPage} />
          <Route path="/verification" component={VerificationPage} />
          <Route path="/emailVerification" component={EmailVerificationPage} />
          <Route path="/smsVerification" component={SMSVerificationPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/totp" component={Totp} />
          <Route path="/email" component={Email} />
          <Route path="/VerifyEmailId" component={VerifyEmailId} />
          <Route path="/sms" component={SMS} />
          <Route path="/VerifyPhone" component={VerifyPhone} />
          <Route exact path="/oauth/authorize" component={OauthPage} />
          <Route exact path="/logout" component={LogoutPage} />
        </React.Fragment>
      </Router>
    </ThemeProvider>
  );
}

export default App;
