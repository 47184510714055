import React, { useState, useEffect } from 'react';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { Redirect } from 'react-router-dom';
import { LoginLayout } from '../components/login-layout/login-layout-new';
import logo from '../static/imgs/BNIportal_logo_RGB_vertical.png';
import {
    Grid,
    Typography,
    Button,
    TextField,
    Paper
} from '@material-ui/core';
import lockImg from '../static/imgs/lockImg.png';
import { Cookies } from 'react-cookie';
import { staticTranslationData } from '../utils/static-translation-text';
import * as staticTextKey from '../utils/translation-variable';

export function EmailVerificationPage(props) {
    const http = Http.getInstance();
    const dataSource = dataSources();
    const [otp, setOtp] = useState("");
    const [error,setError] = useState(false);
    const [verified, setVerified] = useState(false);
    const [over, setOver] = useState(false);
    const [[m, s], setTime] = useState([5, 0]);
    var cookies = new Cookies();

    useEffect(()=>{
        var data = {"type":"email"};
        if((loggedIn === "true") && (mfa_type.type.toLowerCase() === "email")){
          http.postData(`${dataSource.sendOtp}`,data,'')
          .then(res=>{
            console.log("res",res)
          })
          .catch(error=>{
            console.log(error,"error");
          })
        }
    },[]);
    
      useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
      });
    
      const tick = () => {
        if (over) return;
        if ( m === 0 && s === 0) setOver(true);
        else if (m === 0 && s === 0) {
          setTime([ 59, 59]);
        } else if (s == 0) {
          setTime([ m - 1, 59]);
        } else {
          setTime([ m, s - 1]);
        }
      };
    
      const reset = () => {
        setTime([ parseInt(5), parseInt(0)]);
        var data = {"type":"email"};
        http.postData(`${dataSource.sendOtp}`,data,'')
        .then(res=>{
          console.log("res",res)
        })
        .catch(error=>{
          console.log(error,"error");
        })
      };
    
      const verifyCode = () => {
        let code = {"OTP" : otp, "type" : "email"}
        http.postData(`${dataSource.validateOtp}`, code , '')
        .then(res => {
          if (res.status === 200) {
            if(res.data.valid === true) {
              console.log("otp verified");
              setError(false);
              cookies.set('authorised_user', true, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              setVerified(true);
              //setTimeout(()=>{window.location.href="/launcher"},3000)
            }
            else if(res.data.valid === false) {
              console.log("invalid otp");
              setError(true);
              setVerified(false);
            }
          }
          else {
            setError(true);
            setVerified(false);
          }
          })
          .catch(error => {
            console.log(error);
          });
      }

    const handleOtp = (event) => {
        if(event.target.value.length === 0){
            setError(false);
        }
        setOtp(event.target.value);
    };

    const handleCancel = () => {
       window.location.href = "/";
    }

    const mfa_type = cookies.get("mfa_type");
    const loggedIn = cookies.get("loggedIn");
    if((loggedIn === "true") && (mfa_type.type.toLowerCase() === "email")){
      if (verified) {
          return (
              <Redirect
                  to={{
                      pathname: '/launcher'
                  }}
              />
          );
      }
      else {
          return (
                  <React.Fragment>
                      <LoginLayout title="Login">
                          <Paper elevation={3}>
                              <div className="container-wrap">
                                      <form noValidate>
                                          <Grid container spacing={2}>
                                              <Grid item sm={12}>
                                                  <div className=" form-text-color">
                                                      <img className="img-center" src={logo} alt="BNI" height="120px" />
                                                  </div>
                                              </Grid>
                                              <Grid item sm={12}>
                                                  <img className="lockImg" alt="lock icon" src={lockImg} />
                                              </Grid>

                                              <Grid item sm={12}>
                                                  <div className="verifyInstruction"> Enter the user authentication code received </div>
                                                  <Typography
                                                      className="terms-align-center login-top-padding login-bottom-padding verifyMsg"
                                                      color="textSecondary">
                                                      Enter the code sent to your email id.
                                                  </Typography>
                                              </Grid>

                                              <Grid item sm={12}>
                                                  <div className="form-group">
                                                      <TextField
                                                          id="otpField"
                                                          variant="outlined"
                                                          fullWidth
                                                          value={otp}
                                                          onChange={(e) => handleOtp(e)}
                                                          onKeyDown={e => {
                                                            if (e.keyCode === 13) {
                                                                e.preventDefault();
                                                                verifyCode();
                                                            }
                                                          }}
                                                      />
                                                  </div>
                                              </Grid>
                                              
                                              <Grid item sm={12}>
                                                  {error && <div className="loginOtpErrorMsg">Invalid user authentication code. Please retry!</div>}
                                              </Grid>

                                              <Grid item sm={12}>
                                                  {/* <div className="timerSection">
                                                      <div>Remaining time:</div>
                                                      <div>Didn't receive code? <a href="/">Resend again</a></div>
                                                  </div> */}
                            
                                                  <div className="timer">
                                                      <div>Remaining time: {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</div>
                                                      <div>Didn't receive code? <button className="resendBtn" onClick={() => reset()}>Resend again</button></div>
                                                  </div>
                                              </Grid>

                                              <Grid item sm={12}>
                                                  <div className="form-align-center">
                                                      
                                                          <Button
                                                          variant="contained"
                                                          color="primary"
                                                          className="bni-background-color MuiButton-fullWidth button-focus-disable"
                                                          size="large"
                                                          onClick={() => verifyCode()}
                                                          id="login">
                                                              LOGIN
                                                          </Button>
                                                      
                                                          <Button
                                                          variant="outlined"
                                                          className=" classes.button MuiButton-fullWidth button-focus-disable loginCancel"
                                                          onClick={() => handleCancel()}
                                                          id="cancel">
                                                              CANCEL
                                                          </Button>

                                                  </div>
                                              </Grid>
                                          </Grid>
                                      </form>
                                  </div>
                              </Paper>
                          
                          </LoginLayout>
                      </React.Fragment>
                  );
      }
    }
    else {
      window.location.href = "/";
    }    
}
