import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Modal, IconButton, Typography, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import Alert from '@material-ui/lab/Alert';
import { Cookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 470,
    boxShadow: theme.shadows[5],
    
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '150px'
  };
}

export function VerifyEmailId(props) {

  const [code, setCode] = useState();
  const [error, setError] = useState(false);
  const http = Http.getInstance();
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([5, 0]);
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  var cookies = new Cookies();
  const authorised_user = cookies.get("authorised_user");
  const dataSource = dataSources();

  useEffect(()=>{
    var data = {"type" : "email"};
    if(authorised_user === "true"){
      http.postData(`${dataSource.sendOtp}`,data,'')
      .then(res=>{
        console.log("res",res)
      })
      .catch(error=>{
        console.log(error,"error");
      })
    }
  },[]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    if (over) return;
    if ( m === 0 && s === 0) setOver(true);
    else if (m === 0 && s === 0) {
      setTime([ 59, 59]);
    } else if (s == 0) {
      setTime([ m - 1, 59]);
    } else {
      setTime([ m, s - 1]);
    }
  };

  const reset = () => {
    setTime([ parseInt(5), parseInt(0)]);
    var data = {"type":"email"};
    http.postData(`${dataSource.sendOtp}`,data,'')
    .then(res=>{
      console.log("res",res)
    })
    .catch(error=>{
      console.log(error,"error");
    })
  };

  const handleCode = (event) => {
    if(event.target.value.length === 0){
      setError(false);
    }
    setCode(event.target.value);
  };

  const handleClose = () => {
    setOpenAlert(false);
    window.location.href = "/launcher";
  }

  const verifyCode = () => {
    let otp = {"OTP" : code, "type" : "email"}
    http.postData(`${dataSource.validateOtp}`, otp , '')
    .then(res => {
      if (res.status === 200) {
        if(res.data.valid === true) {
          setOpenAlert(true);
          setError(false);
          const user = {
            mfa_enabled: true,
            email_verified: true,
            mfa_type: cookies.get("mfa_type")
          };
          http.patchData(`${dataSource.users}`, user , '')
          .then(res => {
            if (res.status === 200) {
              setOpenAlert(true);
              cookies.set('mfa_enabled', true, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('email_verified', true, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              // setTimeout(()=>{window.location.href="/launcher"},3000);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
        else if(res.data.valid === false) {
          console.log("invalid otp");
          setOpenAlert(false);
          setError(true);
        }
      }
      else {
        setOpenAlert(false);
        setError(true);
      }
      })
      .catch(error => {
        console.log(error);
      });
  }

  if(authorised_user === "true"){
    return (
      <React.Fragment>
          <ThemeProvider theme={theme}>
              <div dir="ltr">
              
                  <AppLayout
                  apps={JSON.parse(sessionStorage.getItem("apps"))}
                  errorDisplay={sessionStorage.getItem("errorDisplay") === "false" ? false : true}
                  errorResponse={sessionStorage.getItem("error")}
                  />

                  {/* { verified && <Alert 
                                icon={false} 
                                className="successNotification"
                                onClose={() => {setVerified(false)}}>
                                  Your Email is successfully verified!
                                </Alert> 
                  } */}

                                <Modal
                                open={openAlert}
                                onClose={handleClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description">
                                <div style={modalStyle} className={classes.modal}>
                                  <div className={'dialogHeader'}>
                                    <Typography color="textPrimary" className={'dialogTitle'}>
                                      SUCCESS
                                    </Typography>
                                    <IconButton onClick={handleClose} className={'closeButton'}>
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <Divider />
                                  <div className={'successMsg'}> Your Email is successfully verified! </div>
                                  <Button 
                                  variant="contained"
                                  color="primary"
                                  onClick={()=>{handleClose()}}
                                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn"
                                  >
                                    OK
                                  </Button> 
                                </div>
                              </Modal>

                  <div className = "totpRoot">
                      <h5 className = "totpTitle">Multifactor Authentication setup for email verification</h5>

                      <div className = "totp">
                          <div className = "emailSection">
                              
                              <img alt="user" className = "userLogo" height = "95px" src={userLogo} />
                              <div>Adding email and phone number authentication ensure that only authorized individuals can access information</div>
                              
                          </div>

                          <div className = "otpVerifySection">
                              
                              <img alt="email" className = "emailUserLogo" src={emailIcon} />
                              <h5> Enter user authentication code sent to your email </h5>
                              <div> Enter the 6 digit code to add and verify email </div>
                              
                              <TextField
                              className="otpField"
                              variant="outlined"
                              fullWidth
                              value={code}
                              required
                              onChange={(e) => handleCode(e)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    verifyCode();
                                }
                              }}
                            />

                              {error && <div className="otpErrorMsg">Invalid user authentication code. Please retry!</div>}

                            
                                  <div className="timer">
                                    <div>Remaining time: {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</div>
                                    <div>Didn't receive code? <button className="resendBtn" onClick={() => reset()}>Resend again</button></div>
                                  </div>
                            

                              <Button 
                              variant="contained"
                              color="primary"
                              onClick={()=>{verifyCode()}}
                              disabled={!s || (code === undefined || code === null)}
                              className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn emailVerifyBtn"
                              >
                                Verify
                              </Button>                   
                          </div>
                      </div>
                  </div>

                  <FooterMenu/>

              </div>
          </ThemeProvider>
      </React.Fragment>
    );
  }
  else {
    window.location.href = "/";
  }
}
