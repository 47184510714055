import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button } from '@material-ui/core';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { Cookies } from 'react-cookie';

export function Email(props) {

  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const [email, setEmail] = useState(cookies.get("email"));
  const [error, setError] = useState(false);

  const handleEmail = (event) => {
    if(event.target.value.length === 0){
      setError(false);
    }
    setEmail(event.target.value);
  };

  const addEmail = () => {
    let regex = new RegExp('^[^._]+[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[a-zA-Z0-9._-]+\.[a-zA-Z]$');
    let isValid = regex.test(email.trim());
    if (isValid) {
      const user = {
        email: email,
      };
      http.patchData(`${dataSource.users}`, user , '')
      .then(res => {
      if (res.status === 200) {
          console.log("user mfa updated",res);
          setError(false);
          cookies.set('email', email, {
            path: '/',
            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
          });
          window.location.href="/verifyEmailId";
      }
      else {
          setError(true);
      }
      })
      .catch(error => {
          console.log(error);
      });
    }
    else{
      setError(true);
    }
  }

  const authorised_user = cookies.get("authorised_user");
  if(authorised_user === "true"){
    return (
      <React.Fragment>
          <ThemeProvider theme={theme}>
              <div dir="ltr">
              
                  <AppLayout
                  apps={JSON.parse(sessionStorage.getItem("apps"))}
                  errorDisplay={sessionStorage.getItem("errorDisplay") === "false" ? false : true}
                  errorResponse={sessionStorage.getItem("error")}
                  />

                  <div className = "totpRoot">
                      <h5 className = "totpTitle">Multifactor Authentication setup for email verification</h5>

                      <div className = "totp">
                          <div className = "emailSection">
                              
                              <img alt="user" className = "userLogo" height = "95px" src={userLogo} />
                              <div>Adding email and phone number authentication ensure that only authorized individuals can access information</div>
                              
                          </div>

                          <div className = "otpVerifySection">
                              
                              <img alt="email" className = "emailUserLogo" src={emailIcon} />
                              <h5> Add your email address </h5>
                              <div> Enter your mail id to which authentication code has to be sent</div>
                              
                              <TextField
                              className="otpField"
                              variant="outlined"
                              fullWidth
                              value={email}
                              required
                              onChange={(e) => handleEmail(e)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    addEmail();
                                }
                              }}
                            />

                              {error && <div className="otpErrorMsg">Enter a valid email</div>}

                              <Button 
                              variant="contained"
                              color="primary"
                              onClick={()=>{addEmail()}}
                              className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn"
                              >
                                Add Email
                              </Button>                   
                          </div>
                      </div>
                  </div>

                  <FooterMenu/>

              </div>
          </ThemeProvider>
      </React.Fragment>
    );
  }
  else {
    window.location.href = "/";
  }
}
