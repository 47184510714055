import React, { useState} from 'react';
import Http from '../services/http';
import { Redirect } from 'react-router-dom';
import { LoginLayout } from '../components/login-layout/login-layout-new';
import logo from '../static/imgs/BNIportal_logo_RGB_vertical.png';
import {
    Grid,
    Typography,
    Button,
    TextField,
    Paper
} from '@material-ui/core';
import lockImg from '../static/imgs/lockImg.png';
import { staticTranslationData } from '../utils/static-translation-text';
import * as staticTextKey from '../utils/translation-variable';
import { Cookies } from 'react-cookie';
import { dataSources } from '../utils/data-sources';

export function VerificationPage(props) {
    var cookies = new Cookies();
    const http = Http.getInstance();
    const dataSource = dataSources();
    const [otp, setOtp] = useState("");
    const [error,setError] = useState(false);
    const [verified, setVerified] = useState(false);

    const handleOtp = (event) => {
        if(event.target.value.length === 0){
            setError(false);
        }
        setOtp(event.target.value);
    };

    const handleCancel = () => {
       window.location.href = "/"
    }

    const onSuccessfullLogin = () => {
        const code = {"code" : parseInt(otp)};
        http.postData(`${dataSource.validateTotp}`, code , '')
        .then(res => {
            if (res.status === 200) {
                if(res.data.valid === true) {
                    console.log("otp verified");
                    setError(false);
                    cookies.set('authorised_user', true, {
                        path: '/',
                        domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                    });
                    setVerified(true);
                }
                else if(res.data.valid === false) {
                    console.log("invalid otp");
                    setError(true);
                    setVerified(false);
                }
            }
            else if(res.status === 400) {
                setError(true);
                setVerified(false);
            }
        })
        .catch(error => {
            console.log(error);
            setVerified(false);
        });
    }

    const mfa_type = cookies.get("mfa_type");
    const loggedIn = cookies.get("loggedIn");
    if((loggedIn === "true") && (mfa_type.type.toLowerCase() === "totp")){
        if (verified) {
            return (
                <Redirect
                    to={{
                        pathname: '/launcher'
                    }}
                />
            );
        }
        else {
            return (
                    <React.Fragment>
                        <LoginLayout title="Login">
                            <Paper elevation={3}>
                                <div className="container-wrap">
                                        <form noValidate>
                                            <Grid container spacing={2}>
                                                <Grid item sm={12}>
                                                    <div className=" form-text-color">
                                                        <img className="img-center" src={logo} alt="BNI" height="120px" />
                                                    </div>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <img className="lockImg" alt="lock icon" src={lockImg} />
                                                </Grid>

                                                <Grid item sm={12}>
                                                    <div className="verifyInstruction"> Enter Authentication code </div>
                                                    <Typography
                                                        className="terms-align-center login-top-padding login-bottom-padding verifyMsg"
                                                        color="textSecondary">
                                                        Enter the code from Authenticator app
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={12}>
                                                    <div className="form-group">
                                                        <TextField
                                                            id="otpField"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={otp}
                                                            onChange={(e) => handleOtp(e)}
                                                            onKeyDown={e => {
                                                                if (e.keyCode === 13) {
                                                                    e.preventDefault();
                                                                    onSuccessfullLogin();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                
                                                <Grid item sm={12}>
                                                    {error && <div className="loginOtpErrorMsg">Invalid user authentication code. Please retry!</div>}
                                                </Grid>

                                                <Grid item sm={12}>
                                                    <div className="form-align-center">
                                                        
                                                            <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="bni-background-color MuiButton-fullWidth button-focus-disable"
                                                            size="large"
                                                            onClick={() => onSuccessfullLogin()}
                                                            id="login">
                                                                LOGIN
                                                            </Button>
                                                        
                                                            <Button
                                                            variant="outlined"
                                                            className=" classes.button MuiButton-fullWidth button-focus-disable loginCancel"
                                                            onClick={() => handleCancel()}
                                                            id="cancel">
                                                                CANCEL
                                                            </Button>

                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </Paper>
                            
                            </LoginLayout>
                        </React.Fragment>
                    );
        }
    }
    else {
        window.location.href = "/";
    }    
}
